import { ERROR, PENDING, LOCATION_SELECTED } from "../../action/location/type";

const initialState = {
  locationList: "",
  locationSelected: "",
  locationIndex: "",
};

export default (state = initialState, action) => {
  // console.log(`In location Reducer${ action.payload}`);

  switch (action.type) {
    case "LOCATION_SUCCESS":
      return {
        ...state,
        locationList: action.payload,
      };
    case PENDING:
      return {
        ...state,
        logindata: [],
        isLoading: true,
        isError: false,
        error: "Some Error Occurred.", // action.error,
      };
    case ERROR:
      return {
        ...state,
        logindata: [],
        isLoading: false,
        isError: true,
        error: "Some Error Occurred.", // action.error,
      };
    case LOCATION_SELECTED:
      return {
        ...state,
        locationSelected: action.payload.plantName,
        prefixLetter: action.payload.prefixLetter,
        locationIndex: action.payload.locationIndex,
      };
    default:
      return state;
  }
};
