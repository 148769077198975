import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./privateRoute";

const LogOut = lazy(() => import("./component/LogOut"));
const Home = lazy(() => import("./component/Home"));
const Login = lazy(() => import("./component/login"));
const AssignLogisticsPartner = lazy(() =>
  import("./component/AssignLogisticsPartner")
);
const AssignTruck = lazy(() => import("./component/AssignTruck"));
const VendorVolumeLimit = lazy(() => import("./component/VendorVolumeLimit"));
const DebitNoteEntry = lazy(() => import("./component/DebitNoteEntry"));
const DeliveryPickupScheduling = lazy(() => import("./component/DeliveryDate"));
const FreightTable = lazy(() => import("./component/FreightCharges"));
const ManageLogisticsPartner = lazy(() =>
  import("./component/AddNewLogisticsPartner")
);
const MisReport = lazy(() => import("./component/MisReport"));
const OrderManagement = lazy(() => import("./component/OrderManagement"));
const PenaltyLogic = lazy(() => import("./component/PenaltyLogic"));
const PenaltyManagement = lazy(() => import("./component/PenaltyManagement"));
const PlantManagement = lazy(() =>
  import("./component/Plant Management/PlantManagement")
);
const POGRNDataValidation = lazy(() =>
  import("./component/PoGRNDataValidation")
);
const PricingAndDailyVolume = lazy(() => import("./component/Pricing"));
const QualityInspection = lazy(() => import("./component/QualityInspection"));
const QualityInspectionCurrentReport = lazy(() =>
  import("./component/QualityInspectionCurrentReportJSR")
);
const QualityInspectionPastReport = lazy(() =>
  import("./component/QualityInspectionPastReportJSR")
);
const QualityManager = lazy(() => import("./component/QualityManager"));
const RoleManagement = lazy(() => import("./component/RoleManagement"));
const Samachaar = lazy(() => import("./component/Samachaar"));
const UpdatePickupStatus = lazy(() => import("./component/UpdatePickupStatus"));
const VolumeIncentive = lazy(() => import("./component/VolumeIncentive"));
const OfferManagement = lazy(() => import("./component/OfferManagement"));
const PriceApproval = lazy(() => import("./component/PriceApproval"));
const AdminSettings = lazy(() => import("./component/AdminSettings"));
const SourcingUtility = lazy(() => import("./component/SourcingUtility"));
const DeviationApproval = lazy(() => import("./component/DeviationApproval"));
const ActiveShipment = lazy(() => import("./component/ActiveShipment"));
const ActiveShipmentLiveDashboard = lazy(() =>
  import("./component/LivePlantDashboardActiveShipment")
);

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      allscreen: [
        {
          screenName: "Pricing / Daily Volume",
          url: "/PricingAndDailyVolume",
          pendingItem: false,
        },
        {
          screenName: "Vendor Volume Limit",
          url: "/VendorVolumeLimit",
          pendingItem: false,
        },
        {
          screenName: "Offer Management",
          url: "/OfferManagement",
          pendingItem: false,
        },
        {
          screenName: "Order Management",
          url: "/OrderManagement",
          pendingItem: false,
        },
        {
          screenName: "Active Shipment",
          url: "/ActiveShipment",
          pendingItem: false,
        },
        {
          screenName: "Active Shipment Dashboard",
          url: "/ActiveShipmentLiveDashboard",
          pendingItem: false,
        },
        {
          screenName: "Price Approval",
          url: "/PriceApproval",
          pendingItem: false,
        },
        {
          screenName: "Deviation Approval",
          url: "/DeviationApproval",
          pendingItem: false,
        },
        {
          screenName: "Admin Settings",
          url: "/AdminSettings",
          pendingItem: false,
        },
        {
          screenName: "Sourcing Utility",
          url: "/SourcingUtility",
          pendingItem: false,
        },
        {
          screenName: "Role Management",
          url: "/RoleManagement",
          pendingItem: false,
        },
        {
          screenName: "Plant Management",
          url: "/PlantManagement",
          pendingItem: false,
        },
        {
          screenName: "Quality Inspection",
          url: "/QualityInspection",
          pendingItem: false,
        },
        {
          screenName: "QI Current Report",
          url: "/QualityInspectionCurrentReport",
          pendingItem: false,
        },
        {
          screenName: "QI Past Report",
          url: "/QualityInspectionPastReport",
          pendingItem: false,
        },
        {
          screenName: "Quality Manager",
          url: "/QualityManager",
          pendingItem: false,
        },
        {
          screenName: "Assign Logistics Partner",
          url: "/assignLogisticsPartner",
          pendingItem: false,
        },
        {
          screenName: "Assign Truck",
          url: "/assignTruck",
          pendingItem: false,
        },
        {
          screenName: "Debit Note Entry",
          url: "/DebitNoteEntry",
          pendingItem: false,
        },
        {
          screenName: "Delivery & Pickup Scheduling",
          url: "/DeliveryPickupScheduling",
          pendingItem: false,
        },
        {
          screenName: "Freight Table",
          url: "/FreightTable",
          pendingItem: false,
        },
        {
          screenName: "Manage Logistics Partner",
          url: "/ManageLogisticsPartner",
          pendingItem: false,
        },
        {
          screenName: "MIS Report",
          url: "/MisReport",
          pendingItem: false,
        },
        {
          screenName: "Penalty Logic",
          url: "/PenaltyLogic",
          pendingItem: false,
        },
        {
          screenName: "Penalty Management",
          url: "/penaltyManagement",
          pendingItem: false,
        },
        {
          screenName: "PO & GRN Data Validation",
          url: "/POGRNDataValidation",
          pendingItem: false,
        },
        {
          screenName: "Samachaar",
          url: "/Samachaar",
          pendingItem: false,
        },
        {
          screenName: "Update Pickup Status",
          url: "/updatePickupStatus",
          pendingItem: false,
        },
        {
          screenName: "Volume Incentive",
          url: "/VolumeIncentive",
          pendingItem: false,
        },
      ],
    };
    document.body.style.backgroundColor = "#F7F7F7";
  }

  componentDidMount() {
    localStorage.setItem("home", true);
    // const { allscreen } = this.state;
    // allscreen.forEach((item) => {
    //   localStorage.setItem(item.screenName, true)
    // })
  }

  updateConfirmModelMessage = (updatedmsg) => {
    this.setState({ message: updatedmsg });
  };

  render() {
    const { message, allscreen } = this.state;
    return (
      <Switch>
        <Suspense fallback={<div> </div>}>
          <Route
            path="/login"
            exact
            render={(props) => (
              <Login {...props} mainContent="Home" title="Home" />
            )}
          />
          <Route
            exact
            path="/adid-logout"
            pageName="LogOut"
            component={LogOut}
          />
          <PrivateRoute
            exact
            path="/"
            pageName="home"
            component={Home}
            allscreen={allscreen}
          />
          <PrivateRoute
            exact
            path="/home"
            pageName="home"
            component={Home}
            allscreen={allscreen}
          />
          <PrivateRoute
            exact
            path="/PricingAndDailyVolume"
            pageName="Pricing / Daily Volume"
            component={PricingAndDailyVolume}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/VendorVolumeLimit"
            pageName="Vendor Volume Limit"
            component={VendorVolumeLimit}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/volumeIncentive"
            pageName="Volume Incentive"
            component={VolumeIncentive}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/DeliveryPickupScheduling"
            pageName="Delivery & Pickup Scheduling"
            component={DeliveryPickupScheduling}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/FreightTable"
            pageName="Freight Table"
            component={FreightTable}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/ManageLogisticsPartner"
            pageName="Manage Logistics Partner"
            component={ManageLogisticsPartner}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/assignLogisticsPartner"
            pageName="Assign Logistics Partner"
            component={AssignLogisticsPartner}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/AssignTruck"
            pageName="Assign Truck"
            component={AssignTruck}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/DeviationApproval"
            pageName="Deviation Approval"
            component={DeviationApproval}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/UpdatePickupStatus"
            pageName="Update Pickup Status"
            component={UpdatePickupStatus}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/PenaltyLogic"
            pageName="Penalty Logic"
            component={PenaltyLogic}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/RoleManagement"
            pageName="Role Management"
            component={RoleManagement}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/PlantManagement"
            pageName="Plant Management"
            component={PlantManagement}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/OrderManagement"
            pageName="Order Management"
            component={OrderManagement}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/POGRNDataValidation"
            pageName="PO & GRN Data Validation"
            component={POGRNDataValidation}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/QualityInspection"
            pageName="Quality Inspection"
            component={QualityInspection}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/QualityInspectionCurrentReport"
            pageName="QI Current Report"
            component={QualityInspectionCurrentReport}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/QualityInspectionPastReport"
            pageName="QI Past Report"
            component={QualityInspectionPastReport}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/QualityManager"
            pageName="Quality Manager"
            component={QualityManager}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/DebitNoteEntry"
            pageName="Debit Note Entry"
            component={DebitNoteEntry}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/penaltyManagement"
            pageName="Penalty Management"
            component={PenaltyManagement}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/Samachaar"
            pageName="Samachaar"
            component={Samachaar}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/MisReport"
            pageName="MIS Report"
            component={MisReport}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/OfferManagement"
            pageName="Offer Management"
            component={OfferManagement}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/PriceApproval"
            pageName="Price Approval"
            component={PriceApproval}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/ActiveShipment"
            pageName="Active Shipment"
            component={ActiveShipment}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/ActiveShipmentLiveDashboard"
            pageName="Active Shipment Dashboard"
            component={ActiveShipmentLiveDashboard}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/AdminSettings"
            pageName="Admin Settings"
            component={AdminSettings}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
          <PrivateRoute
            exact
            path="/SourcingUtility"
            pageName="Sourcing Utility"
            component={SourcingUtility}
            allscreen={allscreen}
            updateConfirmModelMessage={this.updateConfirmModelMessage}
            message={message}
          />
        </Suspense>
      </Switch>
    );
  }
}

export default Routes;
